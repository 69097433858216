<script>

import {
  contentMethods,
} from "@/state/helpers";

/**
 * Audience component
 */
export default {
  page: {
    title: "Preview",
    
  },
  components: { },
  data() {
    return {
      title: '',
      items: [],
      url:null,
      modifiedUrl: null,
      squareSize: "200px",
      squareYSize: "200px",
      squareColor: "#5E40BF",
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      squareLeft: 0,
      squareTop: 0,
      minimized:false,
      personalization: null,
      experienceId:null,
      variantId:null,
      experiences:[],
      variants:[],
      selectedExperience:null,
      selectedVariant:null
    };
  },
  mounted(){
    this.adjustHeight();
    window.addEventListener("resize", this.adjustHeight);
    //this.modifiedUrl = this.url.includes('?') ? this.url + '&gsPreviewVariant=12' : this.url + '?gsPreviewVariant=12'

    document.getElementById("dragme").onmousedown = function(e) {
		this.prevX = e.clientX;
		this.prevY = e.clientY;
		this.mouseDown = true;
	}
	document.getElementById("dragme").onmousemove = function(e) {
    if(this.mouseDown) {
			this.style.left = (Number(this.style.left.substring(0, this.style.left.length-2)) + (e.clientX - this.prevX)) + "px";
			this.style.top = (Number(this.style.top.substring(0, this.style.top.length-2)) + (e.clientY - this.prevY)) + "px";
		}
		this.prevX = e.clientX;
		this.prevY = e.clientY;
	}
	document.getElementById("dragme").onmouseup = function() {
		this.mouseDown = false;
	}

  const personalizationId = this.$route.query?.id;
  this.variantId = this.$route.query?.variant;
  this.url = this.$route.query?.url;
  this.modifiedUrl = this.$route.query?.url;
  
  this.loadPersonalization(personalizationId);
  },
  computed:{
    
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustHeight);
  },
  methods: {
    ...contentMethods, 
    adjustHeight() {
      const iframeElement = this.$refs.myIframe;
      if (iframeElement) {
        const screenHeight = window.innerHeight;
        iframeElement.style.height = `${screenHeight}px`;
      }
    },
    onMinimizeorMaximizeClicked(){
      this.minimized = !this.minimized;
      this.squareYSize = this.minimized ? '30px' : '200px'
    },
    loadPersonalization(personalizationId){
      let loader = this.$loading.show();
      this.getContent(personalizationId).then((personalization)=>{
        this.personalization = personalization.data;
        this.experiences = this.personalization.targets || [];
        if(!this.variantId && this.experiences.length > 0){
          this.selectedExperience = this.experiences[0];
        }else if(this.variantId && this.experiences.length > 0){
          this.selectedExperience = this.experiences.filter(e=> e.variants.filter(v=>v._id == this.variantId).length>0)[0];
        }
          
      }).catch((ex)=>{
        // eslint-disable-next-line no-console
        console.log(ex);
        this.$notify({ type: 'error', text: this.$t('personalizations.get_personalization_error'),title:  this.$t('personalizations.title') });
      }).finally(()=>{
        loader.hide();
      })
    },
    onExperienceSelected(){
      
    },
    onVariantSelected(){

    }
  },
  watch : {
    selectedExperience: {
      immediate: true,
      handler(newVal){
        if(newVal){
          this.variants = newVal.variants || [];
          if(!this.variantId && this.variants.length > 0){
            this.selectedVariant = this.variants[0];
          }else if(this.variantId && this.variants.length > 0){
            this.selectedVariant = this.variants.filter(v=> v._id == this.variantId)[0];
          }
        }
      }
    },
    selectedVariant: {
      immediate: true,
      handler(newVal){

        this.modifiedUrl = this.url.includes('?') ? this.url + `&gsPreviewVariant=${newVal._id}` : this.url + `?gsPreviewVariant=${newVal._id}`
      }
    },
    
  }
};
</script>

<template>
  <div>
    <div class="iframe-container">
      <div
        id="dragme"
        class="draggable-square"
        :style="{ width: squareSize, height: squareYSize, backgroundColor: squareColor }"
        ref="square">
        <div class="row mx-auto" height="25px">
          <p class="col" style="color: white;margin-top: 5px;">Preview</p>
          <i class="bx bx-minus col-2 text-end min" v-on:click="onMinimizeorMaximizeClicked" v-if="!minimized"></i>
          <i class="bx bx-plus col-2 text-end min" v-on:click="onMinimizeorMaximizeClicked" v-if="minimized"></i>
        </div>
        <div v-if="!minimized">
          <div class="row mx-0">
            <label style="color:white; margin-bottom: 0px;"> <i class="bx bxs-magic-wand"></i> Personalización</label>
            <label class="color-box bg-primary bg-soft" style="color: whitesmoke; font-size: 10px;">{{personalization?.name || ''}}</label>
          </div>
          
          <div class="row mx-0 mb-2">
            <div class="col">
              <label style="color:white; margin-bottom: 0px;"><i class="bx bx-target-lock"></i> Experiencia</label>
              <select class="form-control form-control-sm" v-model="selectedExperience" @input="onExperienceSelected">
                <option v-for="option in experiences" v-bind:value="option" :key="option._id">
                  {{ option.name }}
                </option>
              </select>
            </div>
            
          </div>

          <div class="row mx-0">
            <div class="col">
              <label style="color:white; margin-bottom: 0px;"><i class="bx bx bx-test-tube"></i> Variante</label>
              <select class="form-control form-control-sm" v-model="selectedVariant" @input="onVariantSelected">
                <option v-for="option in variants" v-bind:value="option" :key="option._id">
                  {{ option.name }}
                </option>
              </select>
            </div>
            
          </div>
        </div>
      </div>
     
      <iframe ref="myIframe" :src="modifiedUrl" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>
<style>
.iframe-container {
  position: relative;
}

.draggable-square {
  position: absolute;
  cursor: move;
  user-select: none; /* Disable text selection while dragging */
  top: 20px;
  left: 20px;
}

.min{
  color: white;
  font-weight: bold;
  padding-top: 7px;
  padding-right: 7px;
  cursor: pointer;
}
</style>